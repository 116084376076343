import * as React from 'react';
import RcCollapse from 'rc-collapse';
import classNames from 'classnames';
import CollapsePanel from './CollapsePanel';
import Icon from '../icon';
import { ConfigConsumer } from '../config-provider';
import animation from '../_util/openAnimation';
export default class Collapse extends React.Component {
    constructor() {
        super(...arguments);
        this.renderExpandIcon = () => {
            return <Icon type="right" className={`arrow`}/>;
        };
        this.renderCollapse = ({ getPrefixCls }) => {
            const { prefixCls: customizePrefixCls, className = '', bordered } = this.props;
            const prefixCls = getPrefixCls('collapse', customizePrefixCls);
            const collapseClassName = classNames({
                [`${prefixCls}-borderless`]: !bordered,
            }, className);
            return (<RcCollapse {...this.props} prefixCls={prefixCls} className={collapseClassName} expandIcon={this.renderExpandIcon}/>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderCollapse}</ConfigConsumer>;
    }
}
Collapse.Panel = CollapsePanel;
Collapse.defaultProps = {
    bordered: true,
    openAnimation: Object.assign({}, animation, { appear() { } }),
};
